import React from "react";
import { useSelector } from "react-redux";

export const TicketBill = ({ i, translate }) => {
  const ticketID = i + 1;
  const ticket = useSelector((state) =>
    state.ticketState.ticketList.find((ticket) => ticket.id === ticketID)
  );
  const price = ticket
    ? Math.round((translate ? ticket.price / 1.923 : ticket.price) * 100) / 100
    : 0;

  return (
    <div className="ticket-bill">
      <div>
        <big>
          {translate ? `Ticket ${ticketID}` : `Ulaznica ${ticketID}`} {ticket?.ticketName}
        </big>{" "}
        <br />
        {ticket?.category && (translate ? `Zone: ${ticket.category}` : `Zona: ${ticket.category}`)}
        <br />
        {ticket?.row &&
          ticket?.seat &&
          (translate
            ? `Row: ${ticket.row}, Seat: ${ticket.seat}`
            : `Red: ${ticket.row}, Sjedište: ${ticket.seat}`)}
      </div>
      <span>
        {price} <small>{translate ? "€" : "BAM"}</small>
      </span>
    </div>
  );
};
