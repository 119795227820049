import React, { useState, useEffect, useRef } from "react";
import PlusIcon from "../../../assets/ikonice/plus_icon.svg";
import trashCan from "../../../assets/ikonice/trash_can.svg";
import axios from "axios";
import { toastSetup } from "../../../functions/toastSetup";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { AddTicketTheater } from "./AddTicketDisplay";
import SetTemplate from "./SetTemplate";
import TestTemplate from "./TestTemplate";

export const TicketGen = ({ concertData, setConcertData, adminEmail, adminName }) => {
  const [rowNum, setRowNum] = useState(0);
  const [tickets, setTickets] = useState([]);
  const [totalTickets, setTotalTickets] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loader, setLoader] = useState(false);
  const firstInvalidInputRef = useRef(null);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [template, setTemplate] = useState(concertData?.freeSaleTemplate || "");
  let placeData;

  placeData = {
    ground_plan: concertData.place.ground_plan,
    location: concertData.place.city,
    name: concertData.place.place,
    type: concertData.place.type,
    zones: concertData.tickets.online_sale.zones,
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const categoryNames = tickets.map((ticket) => ticket.categoryName.trim());
      const hasDuplicates = new Set(categoryNames).size !== categoryNames.length;

      if (hasDuplicates) {
        toast.warn(
          "Molimo unesite jedinstvene nazive zona ulaznica.",
          toastSetup("top-right", 3000)
        );
        return;
      }

      const hasInvalidData = tickets.some(
        (ticket) => !ticket.categoryName || !ticket.ticketPrice || !ticket.ticketType
      );

      if (hasInvalidData) {
        const invalidFields = tickets
          .map((ticket, index) => ({
            index,
            fields: ["categoryName", "ticketPrice", "ticketType"].filter((field) => !ticket[field]),
          }))
          .filter((ticket) => ticket.fields.length > 0);

        toast.warn(
          "Molimo unesite sve potrebne podatke za zone ulaznica.",
          toastSetup("top-right", 3000)
        );
        if (invalidFields.length > 0) {
          setInvalidInputs(invalidFields);
          return;
        }

        if (!firstInvalidInputRef.current) {
          return;
        }

        const firstInvalidInputName = firstInvalidInputRef.current.name;
        if (["categoryName", "ticketPrice", "ticketType"].includes(firstInvalidInputName)) {
          firstInvalidInputRef.current.focus();
        }
        return;
      }

      const totalTicketNum = tickets.reduce(
        (total, ticket) => total + parseInt(ticket.ticketsNum || 0),
        0
      );
      // Check if the total number of tickets is 0
      if (totalTicketNum === 0 && !concertData.type.includes("theaters")) {
        toast.warn(
          "Nema ulaznica za ispis. Unesite broj ulaznica za neku od zona.",
          toastSetup("top-right", 3000)
        );
        return;
      }

      let filteredTickets = tickets.filter(
        (ticket) => ticket.ticketsNum === "" || ticket.ticketsNum !== 0
      );

      filteredTickets.forEach((ticket) => {
        if (ticket.rows) {
          Object.keys(ticket.rows).forEach((rowKey) => {
            if (ticket.rows[rowKey].seats.length === 0) {
              delete ticket.rows[rowKey];
            }
          });
        }
      });

      if (filteredTickets.length === 0) {
        toast.warn(
          "Nema ulaznica za ispis. Unesite broj ulaznica za neku od zona.",
          toastSetup("top-right", 3000)
        );
        return;
      }

      toast.success(
        `Ulaznice su tijeku izrade. Po završetku ih preuzmite putem poveznice koja će biti poslana na ${adminEmail}`,
        toastSetup("top-right", 5000)
      );
      await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/freeSale/generate-tickets`, {
        ticketGenData: filteredTickets,
        concertData,
        adminEmail,
        adminName,
        template,
      });

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/concerts/id/${concertData._id}`
        );
        setConcertData(response.data[0]);
      } catch (error) {
        console.error("Greška pri dohvaćanju podataka o koncertu:", error);
      }
      toast.success("Generiranje ulaznica uspješno!", toastSetup("top-right", 3000));
    } catch (error) {
      toast.error(
        "Problem s generiranjem ulaznica. Pokušajte ponovno kasnije...",
        toastSetup("top-right", 3000)
      );
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (concertData.tickets.free_sale.zones && concertData.place.type === "hall") {
      const initialRows = Object.keys(concertData.tickets.free_sale.zones).map((categoryName) => ({
        categoryName,
        ticketType: concertData.tickets.free_sale.zones[categoryName].name,
        ticketsNum: "",
        ticketPrice: concertData.tickets.free_sale.zones[categoryName].price.toString(),
        ticketsAlready: concertData.tickets.free_sale.zones[categoryName].max_amount,
      }));
      setTickets(initialRows);
      setRowNum(initialRows.length);
    } else {
      setRowNum(0);
      setTickets([]);
    }
  }, [concertData]);

  const handleAddRow = () => {
    setRowNum((prevRowNum) => prevRowNum + 1);
    // Add a new ticket object to the tickets state when adding a row
    setTickets([...tickets, { categoryName: "", ticketType: "", ticketsNum: "", ticketPrice: "" }]);
  };

  const handleRemoveRow = (index) => {
    const updatedTickets = [...tickets];
    updatedTickets.splice(index, 1);
    setTickets(updatedTickets);
    setRowNum((prevRowNum) => prevRowNum - 1);
  };

  const handleInputChange = (e, index, field) => {
    const { name, value } = e.target;
    const updatedTickets = [...tickets];
    updatedTickets[index] = {
      ...updatedTickets[index],
      [name]: value,
    };
    setTickets(updatedTickets);

    // Remove the invalid input from invalidInputs
    setInvalidInputs((prevInvalidInputs) =>
      prevInvalidInputs.filter((input) => input.index !== index)
    );
  };
  useEffect(() => {
    // Recalculate total number of tickets, total amount, and provision
    let totalTickets = 0;
    let totalAmount = 0;
    tickets.forEach((ticket) => {
      const ticketsNum = parseInt(ticket.ticketsNum, 10);
      const ticketPrice = parseFloat(ticket.ticketPrice);

      if (!isNaN(ticketsNum) && !isNaN(ticketPrice)) {
        totalTickets += ticketsNum;
        totalAmount += ticketsNum * ticketPrice;
      }
    });
    setTotalTickets(totalTickets);
    setTotalAmount(totalAmount.toFixed(2)); // Rounding to 2 decimal places
  }, [tickets]);

  return (
    <div className="generator-container">
      {/* Still developing */}
      {/* <SetTemplate template={template} setTemplate={setTemplate} />
      <TestTemplate template={template} concertId={concertData._id} /> */}
      <form onSubmit={handleFormSubmit}>
        <div className="tickets-categories-container container-fluid">
          {concertData && concertData.place.type !== "hall" ? (
            <AddTicketTheater
              key={concertData._id}
              placeData={placeData}
              tickets={tickets}
              setTickets={setTickets}
              freeSale={concertData.tickets.free_sale}
              onlineSale={concertData.tickets.online_sale}
            />
          ) : (
            Array.from({ length: rowNum }).map((_, i) => {
              const isDeletableRow =
                i >= Object.keys(concertData.tickets.free_sale.zones || []).length;
              const categoryNameRef = i === 0 ? firstInvalidInputRef : null;
              const ticketPriceRef = i === 0 ? null : firstInvalidInputRef;

              return (
                <div key={i} className="row">
                  <div className="col-lg-6">
                    <input
                      className={`event-input category-name ${
                        invalidInputs.some(
                          (input) => input.index === i && input.fields.includes("categoryName")
                        )
                          ? "invalid-input"
                          : ""
                      }`}
                      name="categoryName"
                      value={tickets[i]?.categoryName || ""}
                      placeholder="Zona ulaznice"
                      type="text"
                      onChange={(e) => handleInputChange(e, i, "categoryName")}
                      onInput={(e) => {
                        e.target.style = "outline: none;";
                      }}
                      disabled={!isDeletableRow}
                      ref={categoryNameRef}
                    />
                    <input
                      className={`event-input ticket-type ${
                        invalidInputs.some(
                          (input) => input.index === i && input.fields.includes("ticketType")
                        )
                          ? "invalid-input"
                          : ""
                      }`}
                      name="ticketType"
                      value={tickets[i]?.ticketType || ""}
                      placeholder="Tip ulaznice"
                      type="text"
                      onChange={(e) => handleInputChange(e, i, "ticketType")}
                      onInput={(e) => {
                        e.target.style = "outline: none;";
                      }}
                      disabled={!isDeletableRow}
                    />
                  </div>
                  <div className="col-lg-6 add-tickets-right-col">
                    <input
                      className="event-input tickets-num"
                      name="ticketsNum"
                      value={tickets[i]?.ticketsNum || ""}
                      placeholder={`Broj ulaznica. (${tickets[i]?.ticketsAlready || "0"})`}
                      type="text"
                      onChange={(e) => handleInputChange(e, i, "ticketsNum")}
                      onInput={(e) => {
                        e.target.style = "outline: none;";
                      }}
                    />
                    <div className="price">
                      <input
                        className={`event-input ticket-price ${
                          invalidInputs.some(
                            (input) => input.index === i && input.fields.includes("ticketPrice")
                          )
                            ? "invalid-input"
                            : ""
                        }`}
                        name="ticketPrice"
                        value={tickets[i]?.ticketPrice || ""}
                        placeholder="Cijena ulaznice"
                        type="text"
                        onChange={(e) => handleInputChange(e, i, "ticketPrice")}
                        onInput={(e) => {
                          e.target.style = "outline: none;";
                        }}
                        disabled={!isDeletableRow}
                        ref={ticketPriceRef}
                      />
                      <span>BAM</span>
                      {isDeletableRow && (
                        <img src={trashCan} alt="trash can" onClick={() => handleRemoveRow(i)} />
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {concertData && concertData.place.type === "hall" && (
          <img onClick={handleAddRow} className="add-category-icon" src={PlusIcon} alt="Add" />
        )}
        <h6 style={{ marginLeft: "10px" }}>Ulaznice za printanje</h6>
        <div className="total-summ">
          <div>
            <p>Ukupan broj ulaznica: {totalTickets}</p>
            <p>Ukupan iznos: {totalAmount} BAM</p>
          </div>

          {(concertData.place.type === "theater" || concertData.place.type === "stadium") && (
            <div className="legend">
              <div className="online">Online prodaja</div>
              <div className="free">Slobodna prodaja</div>
              <div className="available">Dostupne zone</div>
              <div className="selected">Odabrane zone</div>
              <div className="mixed">Kombinirana prodaja</div>
            </div>
          )}
        </div>
        {(concertData.place.type === "theater" || concertData.place.type === "stadium") && (
          <div className="total-per-zone-container">
            <table className="total-per-zone">
              <thead>
                <tr>
                  <th>Zona</th>
                  <th>Tip</th>
                  <th>Broj ulaznica</th>
                  <th>Cijena (BAM)</th>
                  <th>Izbriši</th>
                </tr>
              </thead>
              <tbody>
                {tickets.length === 0 && (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center", padding: "15px 0" }}>
                      Dodaj zone za printanje
                    </td>
                  </tr>
                )}
                {tickets.map((ticket, i) => (
                  <tr key={i}>
                    <td>{ticket.categoryName}</td>
                    <td>
                      <input
                        type="text"
                        value={ticket.ticketType}
                        onChange={(e) =>
                          setTickets((prevTickets) =>
                            prevTickets.map((ticket, index) =>
                              index === i ? { ...ticket, ticketType: e.target.value } : ticket
                            )
                          )
                        }
                      />
                    </td>
                    <td>{ticket.ticketsNum}</td>
                    <td>
                      <input
                        type="number"
                        value={ticket.ticketPrice}
                        onChange={(e) =>
                          setTickets((prevTickets) =>
                            prevTickets.map((ticket, index) =>
                              index === i ? { ...ticket, ticketPrice: e.target.value } : ticket
                            )
                          )
                        }
                      />
                    </td>
                    <td>
                      <img
                        src={trashCan}
                        alt="trash can"
                        onClick={() => setTickets((prev) => prev.filter((_, index) => index !== i))}
                        className="remove-ticket"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="button-wrapper">
          <button type="submit" className="add-tickets-btn">
            Izradi ulaznice
          </button>
          {loader ? (
            <div className="loader">
              <Bars height="50" width="50" color="#455cd9" />{" "}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default TicketGen;
