import React, { useEffect, useState, useCallback } from "react";
// Images
import Logo from "../assets/logo/logo.svg";
import Menu from "../assets/ikonice/menu.svg";
// Components
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Login } from "../auth/Login";
import { Register } from "../auth/Register";
import { toast } from "react-toastify";
import { toastSetup } from "../functions/toastSetup";
// Other
import { useSelector, useDispatch } from "react-redux";
import { setToken, setUserID } from "../store/userSlice";
import { setLoginIsOpen } from "../store/loginSlice";
import { LanguageToggler } from "./LanguageToggler";
import { Tickets, UserPen, LogOut, LogIn, UserPlus } from "lucide-react";

const CATEGORIES = ["", "concert", "festival", "sport", "theaters", "other"];

const languageMap = {
  hr: {
    naslovna: "Naslovna",
    concert: "Koncerti",
    festival: "Festivali",
    sport: "Sport",
    theaters: "Predstave",
    other: "Ostalo",
    izvodaci: "Izvođači",
    kontakt: "Kontakt",
    prijava: "Prijavi se",
    registrirajSe: "Registriraj se",
    profil: "Profil",
    ulaznice: "Ulaznice",
    odjaviSe: "Odjavi se",
    successLogOut: "Uspješno ste se odjavili!",
  },
  en: {
    naslovna: "Home",
    concert: "Concerts",
    festival: "Festivals",
    sport: "Sports",
    theaters: "Theaters",
    other: "Other",
    izvodaci: "Artists",
    kontakt: "Contact",
    prijava: "Login",
    registrirajSe: "Register",
    profil: "Profile",
    ulaznice: "Tickets",
    odjaviSe: "Logout",
    successLogOut: "Successfully logged out!",
  },
};

export const Navbar = () => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginOpen = useSelector((state) => state.loginState.isLoginOpen);
  const language = useSelector((state) => state.userState.language);
  const languageStrings = languageMap[language] ?? {};

  const logout = useCallback(() => {
    dispatch(setUserID(""));
    dispatch(setToken(""));
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    toast.success(languageStrings?.successLogOut ?? "Logout", toastSetup("top-right", 2000));
  }, [dispatch, navigate, languageStrings]);

  useEffect(() => {
    if (location.pathname === "/profile" && userId === "" && token === "") {
      dispatch(setLoginIsOpen(true));
    }
  }, [location, userId, token, dispatch]);

  const handleScroll = useCallback(() => {
    setIsNavbarCollapsed(true);
  }, []);

  useEffect(() => {
    if (location.pathname === "/qr_scanner" || location.pathname === "/controller_login") return;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location, handleScroll]);

  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      if (isLoginOpen || isRegisterOpen) {
        document.body.style.overflow = "hidden";
        const stickyNav = document.querySelector(".sticky-nav");
        if (stickyNav) stickyNav.style = "backdrop-filter: none !important";
      } else {
        document.body.style.overflow = "unset";
        const stickyNav = document.querySelector(".sticky-nav");
        if (stickyNav) stickyNav.style = "backdrop-filter: blur(20px)";
      }
    }, 150);
  }, [isLoginOpen, isRegisterOpen]);

  const isActive = (path) => {
    const typeParam = new URLSearchParams(location.search).get("type");
    return typeParam === path;
  };

  if (location.pathname === "/qr_scanner" || location.pathname === "/controller_login") return null;

  return (
    <header className="nav-wrapper">
      <nav className="navbar navbar-expand-xl custom-navbar">
        <div className="container-fluid">
          <Link
            className="navbar-brand"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            to="/"
          >
            <img src={Logo} alt="Logo" />
            <h1 style={{ fontSize: "22px", color: "white", margin: "0" }}>event.ba</h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={!isNavbarCollapsed}
            aria-label="Toggle navigation"
            onClick={() => setIsNavbarCollapsed((prevState) => !prevState)}
          >
            <img src={Menu} className="navbar-toggler-icon" alt="Menu" />
          </button>
          <div
            className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`}
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav">
              <div className="navbar-middle-part">
                {CATEGORIES.map((type, index) => (
                  <li className="nav-item" key={index}>
                    <NavLink
                      className={`nav-link ${isActive(type) ? "navlink-active" : ""}`}
                      to={!type ? "/" : `/list_page?type=${type}`}
                      onClick={() => !isNavbarCollapsed && setIsNavbarCollapsed(true)}
                    >
                      {languageStrings[type === "" ? "naslovna" : type]}
                    </NavLink>
                  </li>
                ))}
                <LanguageToggler />
              </div>
              <div className="navbar-auth">
                {!token && !userId ? (
                  <>
                    <div
                      className="navbar-link"
                      onClick={() => {
                        dispatch(setLoginIsOpen(true));
                      }}
                    >
                      <div className="flex-center">
                        <LogIn size={20} />
                        <span>{languageStrings.prijava}</span>
                      </div>
                    </div>

                    <div
                      className="navbar-link"
                      onClick={() => {
                        setIsRegisterOpen(!isRegisterOpen);
                      }}
                    >
                      <div className="flex-center">
                        <UserPlus size={20} />
                        <span>{languageStrings.registrirajSe}</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <li className="navbar-link">
                      <Link
                        to={"/profile?activeNavItem=Ažuriraj podatke"}
                        onClick={() => !isNavbarCollapsed && setIsNavbarCollapsed(true)}
                      >
                        <div className="flex-center">
                          <UserPen size={20} />
                          <span>{languageStrings.profil}</span>
                        </div>
                      </Link>
                    </li>
                    <li className="navbar-link">
                      <Link
                        to={"/profile?activeNavItem=Moje ulaznice"}
                        onClick={() => !isNavbarCollapsed && setIsNavbarCollapsed(true)}
                      >
                        <div className="flex-center">
                          <Tickets size={20} />
                          <span>{languageStrings.ulaznice}</span>
                        </div>
                      </Link>
                    </li>

                    <li className="navbar-link" onClick={logout}>
                      <div className="flex-center">
                        <LogOut size={20} />
                        <span>{languageStrings.odjaviSe}</span>
                      </div>
                    </li>
                  </>
                )}
              </div>
            </ul>
          </div>
        </div>
        {isLoginOpen && <Login setIsRegisterOpen={setIsRegisterOpen} />}
        {isRegisterOpen && (
          <Register isRegisterOpen={isRegisterOpen} setIsRegisterOpen={setIsRegisterOpen} />
        )}
      </nav>
    </header>
  );
};
