import React, { useEffect, useState } from "react";
import ImageMapper, { Mode } from "../../draw-place/image-mapper/ImageMapper";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";

export const Theater = ({
  placeData,
  setRows,
  rows,
  setGroundPlan,
  page,
  freeSale,
  onlineSale,
}) => {
  const [groundPlanImg, setImg] = useState(null);
  const [modalWindow, setModalWindow] = useState(false);
  const [selectedZoneData, setSelectedZoneData] = useState();
  const [selectedZone, setSelectedZone] = useState([]);
  const [price, setPrice] = useState("");
  const [type, setType] = useState("");
  const [ticketNum, setTicketNum] = useState("");

  //Setup all zones to on_sale = false on page load
  useEffect(() => {
    if (placeData && placeData.zones) {
      const zoneKeys = Object.keys(placeData.zones);
      const updatedRows = {};

      // Iterate over each zone key and set on_sale to false
      zoneKeys.forEach((zoneKey) => {
        updatedRows[zoneKey] = {
          ...rows[zoneKey],
          on_sale: false,
        };
      });

      // Update the rows state with the updated on_sale values
      setRows(updatedRows);
    }
  }, []);

  // Load ground image
  useEffect(() => {
    const loadImage = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/static/ground-plans/${placeData.ground_plan}`
        );

        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);

        // Extract the image name from the URL
        const imageName = placeData.ground_plan.split("/").pop();

        // Create an image element to calculate its dimensions
        const imgElement = new Image();

        // Set up the onload event handler
        imgElement.onload = () => {
          setImg({
            src: imageUrl,
            width: imgElement.width,
            height: imgElement.height,
          });

          // Set the image name in setGroundPlan
          setGroundPlan(imageName);
        };

        // Set the src AFTER defining the onload handler
        imgElement.src = imageUrl;
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [placeData.name]);

  // Zone click
  function handleZoneClick(e, zoneData) {
    setModalWindow(true);
    setSelectedZoneData(zoneData);
    if (document.querySelector(".highlighted"))
      document.querySelector(".highlighted").classList.remove("highlighted");
    e.classList.add("highlighted");
  }
  // Save zone
  function saveZone(e) {
    e.preventDefault();
    const isCheckboxChecked = document?.querySelector(".disable-zone-checkbox").checked;

    let zoneKey = selectedZoneData[0];

    if (isCheckboxChecked) {
      document.getElementById(`${zoneKey}`).classList.remove("done");
      setType("");
    } else {
      if (!price) {
        document.querySelector(".price-input").style = "outline: 2px solid #f4cd46;";
        toast.warn("Unesite cijenu", toastSetup("top-right", 3000));
        return;
      }
      if (!type) {
        document.querySelector(".ticket-type").style = "outline: 2px solid #f4cd46;";
        toast.warn("Molimo unesite tip.", toastSetup("top-right", 3000));
        return;
      }
      // Do work if everything is alright
      // Fill the seats array, that seats are avilable

      document.querySelector(".highlighted").classList.add("done");
    }

    // Update the rows state to include the new seats array
    setRows((prevRows) => {
      const zone = prevRows[zoneKey];

      const rows = zone.rows;
      if (rows) {
        for (let key in rows) {
          if (rows.hasOwnProperty(key)) {
            let row = rows[key];
            const totalSeats = Number(row.total_seats);

            // Create an array of seat numbers based on the total_seats value
            if (!isCheckboxChecked) row.seats = Array.from({ length: totalSeats }, (_, i) => i + 1);
            else {
              row.seats = [];
            }
          }
        }

        // Calculate the total number of seats for all rows in the zone
        const totalSeatsInZone = Object.values(zone.rows).reduce(
          (total, row) => total + Number(row.total_seats),
          0
        );

        // Create a new row object
        const newZone = {
          price: Number(price),
          name: isCheckboxChecked ? "" : type,
          max_amount: totalSeatsInZone,
          amount: totalSeatsInZone,
          rows,
        };

        // Set tickets info for printing drawed places

        return {
          ...prevRows,
          [zoneKey]: {
            ...prevRows[zoneKey],
            price: newZone.price,
            name: newZone.name,
            on_sale: isCheckboxChecked ? false : true,
            max_amount: newZone.max_amount,
            amount: newZone.amount,
            rows,
          },
        };
      }
      return prevRows;
    });

    document.querySelector(".highlighted").classList.remove("highlighted");
    setModalWindow(false);
  }

  function saveRows(e) {
    e.preventDefault();
    const rowsPrice = document.querySelector(".rows-price").value;
    const rowsCategory = document.querySelector(".rows-category").value;

    if (rowsPrice === "" || rowsCategory === "" || !selectedZone[0]) {
      toast.warn(
        "Molimo unesite cijenu, kategoriju i odaberite barem jednu zonu.",
        toastSetup("top-right", 3000)
      );
      return;
    }

    // Update the rows state for each selected zone
    selectedZone.forEach((zoneKey) => {
      document.getElementById(`${zoneKey}`).classList.add("done");
      if (rows) {
        setRows((prevRows) => {
          const zone = prevRows[zoneKey];
          const rows = zone.rows;

          for (let key in rows) {
            if (rows.hasOwnProperty(key)) {
              let row = rows[key];
              const totalSeats = Number(row.total_seats);

              // Create an array of seat numbers based on the total_seats value

              row.seats = Array.from({ length: totalSeats }, (_, i) => i + 1);
            }
          }

          // Calculate the total number of seats for all rows in the zone
          const totalSeatsInZone = Object.values(zone.rows).reduce(
            (total, row) => total + Number(row.total_seats),
            0
          );

          // Update the 'amount' field with the total number of seats
          return {
            ...prevRows,
            [zoneKey]: {
              ...zone, // Use the 'zone' object directly here
              price: Number(rowsPrice),
              name: rowsCategory,
              amount: totalSeatsInZone,
              max_amount: totalSeatsInZone,
              on_sale: true,
              rows,
            },
          };
        });
      }
    });

    toast.success(
      "Uspješno ste dodali cijenu i kategoriju za odabrane zone.",
      toastSetup("top-right", 3000)
    );
  }

  const handleCheckboxChange = (zoneKey) => {
    const updatedSelectedZone = [...selectedZone];

    if (updatedSelectedZone.includes(zoneKey)) {
      // If the zoneKey is already in the selectedZone array, remove it
      const index = updatedSelectedZone.indexOf(zoneKey);
      if (index !== -1) {
        updatedSelectedZone.splice(index, 1);
      }
    } else {
      // If the zoneKey is not in the selectedZone array, add it
      updatedSelectedZone.push(zoneKey);
    }

    setSelectedZone(updatedSelectedZone);
  };

  // Function to select all checkboxes
  const selectAll = () => {
    const allZoneKeys = Object.keys(placeData.zones);

    if (selectedZone.length === allZoneKeys.length) {
      // If all are already selected, unselect all
      setSelectedZone([]);
    } else {
      setSelectedZone(allZoneKeys);
    }
  };

  // Function to unselect all checkboxes
  const unselectAll = () => {
    setSelectedZone([]);
  };

  return (
    groundPlanImg && (
      <>
        {modalWindow ? (
          <>
            <div className="modal">
              <div className="disable-zone-wrapper">
                <label htmlFor="disable-zone-checkbox">Zabrani prodaju</label>
                <input className="disable-zone-checkbox" type="checkbox" />
              </div>
              <div>
                <label>Cijena zone</label>
                <input
                  value={price}
                  type="number"
                  placeholder="Unesite cijenu (BAM)"
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
              </div>
              <div>
                <label>Tip ulaznice</label>
                <input
                  value={type}
                  type="text"
                  placeholder="Tip ulaznice"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                />
              </div>
              {selectedZoneData && !selectedZoneData[1]?.rows && (
                <>
                  <label>Broj ulaznica</label>
                  <input
                    value={ticketNum}
                    type="text"
                    onChange={(e) => {
                      setTicketNum(e.target.value);
                    }}
                  />
                </>
              )}
              <button
                type="button"
                onClick={(e) => {
                  saveZone(e);
                }}
              >
                Spremi
              </button>
            </div>
            <div
              onClick={() => {
                setModalWindow(false);
              }}
              className="blur"
            ></div>
          </>
        ) : (
          ""
        )}
        {groundPlanImg && (
          <div className="organize-event-plan-wrapper">
            <div
              id="tooltip"
              display="none"
              style={{ position: "absolute", display: "none" }}
            ></div>
            <ImageMapper
              mode={Mode.SELECT}
              cb={(editor) => {
                editor.loadImage(groundPlanImg.src); // Load the URL directly
                editor.polygon();
              }}
              options={{
                width: groundPlanImg.width,
                height: groundPlanImg.height,
              }}
              handleZoneClick={handleZoneClick}
              preDrawnShapes={rows}
              page={page}
              freeSale={freeSale}
              onlineSale={onlineSale}
            />
            <div className="select-all-seats-wrapper">
              <p style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                Odredi cijenu i tip za više redova
              </p>

              <div className="organize-zones-wrapper">
                {Object.entries(placeData.zones).map(([zoneKey, value], i) => {
                  const isChecked = selectedZone.includes(zoneKey);

                  return (
                    <div className="selling-row" key={i}>
                      <p>Zona {zoneKey}</p>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(zoneKey)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="zones-input-wrapper">
                <input className="rows-price" type="number" placeholder="Cijena (BAM)" />
                <input className="rows-category" type="text" placeholder="Tip" />
                <div className="button-wrapper">
                  {selectedZone.length < 1 ? (
                    <button type="button" onClick={selectAll}>
                      Odaberi sve
                    </button>
                  ) : (
                    <button type="button" onClick={unselectAll}>
                      Poništi odabir
                    </button>
                  )}

                  <button
                    type="button"
                    onClick={(e) => {
                      saveRows(e);
                    }}
                  >
                    Spremi
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
};
