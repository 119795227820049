import React from "react";

const CategoryCard = ({ category, total, isValid }) => {
  const scanned = total - isValid;
  const progress = 100 - (scanned / total) * 100;

  return (
    <div className="entrance-category-card">
      <strong>{category}</strong>
      <span>
        Skenirano: {scanned}
        {total ? ` / ${total}` : null}
      </span>
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${progress}%` }} // Move the fill based on progress
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
