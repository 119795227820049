import React, { useState } from "react";
import axios from "axios";
import { EventSearch } from "../ticket-admin/EventSearch";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";

const ManagePresale = ({ allEvents, userId }) => {
  const [concertData, setConcertData] = useState(null);
  const [emailList, setEmailList] = useState(""); // State to store entered email addresses
  // const [ticketAmount, setTicketAmount] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);

  const handleSelectChange = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/concerts/id/${id}`);
      setConcertData(response.data[0]);
      setFilteredEvents([]);
      setSearchTerm("");
    } catch (error) {
      console.error("Error fetching concert data:", error);
    }
  };

  const handleEmailChange = (event) => {
    setEmailList(event.target.value); // Update email list state as user types
  };

  const handleButtonClick = () => {
    const emailsArray = emailList.trim(" ").split("\n");

    const body = {
      concert_id: concertData._id,
      // max_tickets_per_email: ticketAmount,
      number_of_emails: emailsArray.length,
      user_id: userId,
      list_of_emails: emailsArray,
    };

    const fetchData = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/presale/create_presale`, body);
        toast.success("Uspješno ste kreirali listu za pretprodaju", toastSetup("top-center", 3000));
      } catch (error) {
        toast.error(
          `Došlo je do pogreške pretraživanja. ${error?.response?.data.message || ""}`,
          toastSetup("top-center", 3000)
        );
      }
    };
    fetchData();
  };
  return (
    <>
      <h6>Odaberi događaj</h6>
      <EventSearch
        allEvents={allEvents}
        handleSelectChange={handleSelectChange}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filteredEvents={filteredEvents}
        setFilteredEvents={setFilteredEvents}
      />
      {concertData && (
        <>
          <div className="container">
            <div className="top">
              <div className="info">
                <div className="performer">{concertData.performer_name}</div>
                <div className="city">
                  {concertData.place.city}, {concertData.place.place}, {concertData.place.country}
                </div>
                <div className="date">
                  {new Date(concertData.time_of_event).toLocaleString("hr-HR", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    timeZone: "Europe/Zagreb",
                  })}
                </div>
              </div>
              <img
                className="img-admin"
                src={`${process.env.REACT_APP_API_URL}/static/event-images/${concertData.poster.landscape}`}
                alt="Portrait"
              />
            </div>
            <div className="presale-form-container">
              {/* <label style={{ textAlign: "center" }} htmlFor="ticketAmount">
                Broj ulaznica po emailu
              </label>
              <input
                style={{ width: "40%", margin: "10px auto", textAlign: "center" }}
                id="ticketAmount"
                type="string"
                value={ticketAmount || 1}
                onChange={handleTicketAmountChange} // Handle changes in the ticket amount input
                placeholder="Amount of tickets per email"
              /> */}
              <label style={{ textAlign: "center" }} htmlFor="emails">
                Email list:
              </label>
              <textarea
                style={{ width: "40%", margin: "10px auto" }}
                id="emails"
                name="emails"
                rows="4"
                cols="50"
                value={emailList}
                onChange={handleEmailChange} // Handle changes in the textarea
              />
              {/* Button to submit email list */}
            </div>
            <button onClick={handleButtonClick}>Spremi</button>
          </div>
        </>
      )}
    </>
  );
};

export default ManagePresale;
