import "devextreme/dist/css/dx.light.css";
import { ToastContainer } from "react-toastify";
import { Footer, Navbar } from "./components";
import { AllRoutes } from "./routes/AllRoutes";
import { useDispatch } from "react-redux";
import { setLanguage, setToken, setUserID } from "./store/userSlice";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function App() {
  ReactGA.initialize(process.env.REACT_APP_GA);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    const fullURL = window.location.href;

    // Send the pageview event to Google Analytics with the full URL
    ReactGA.send({ hitType: "pageview", type: location.pathname, page: fullURL });
  }, [location.pathname]);

  let userId;
  let token;
  let language;

  if (localStorage.getItem("userId")) {
    userId = localStorage.getItem("userId");
    token = localStorage.getItem("token");
    language = localStorage.getItem("language");
  } else if (sessionStorage.getItem("userId")) {
    userId = sessionStorage.getItem("userId");
    token = sessionStorage.getItem("token");
    language = localStorage.getItem("language");
  }

  if (userId) {
    dispatch(setUserID(userId));
    dispatch(setToken(token));
    dispatch(setLanguage(language || "hr"));
  }

  return (
    <div className="App">
      <Navbar />
      <AllRoutes />
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default App;
