import React, { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";

const LoaderBuyPage = ({ setLoading, translate }) => {
  const [sendingTickets, setSendingTickets] = useState(false);
  const text = translate
    ? {
        wait: "Please wait for server response...",
        checkEmail:
          "If You completed the transaction please wait for response from server. Check your email (All mail) for successful transaction confirmation. If you receive a report of a successful transaction and do not receive tickets within 10 minutes, report the problem to info@event.ba.",
        backToHome: "Back to home",
        close: "Close",
        warning: "You won't receive your tickets if you leave or reload this page!",
      }
    : {
        wait: "Pričekajte formu za plaćanje...",
        checkEmail:
          "Ukoliko ste izvršili transakciju pričekajte odgovor servera. Nakon uspješne transakcije dobit će te email za potvrdu narudžbe gdje možete provjeriti status vaše transakcije. Ulaznice će biti poslane na email, a možete ih preuzeti na vašem profilu pod rubrikom Moje ulaznice. Za sve poteškoće javite nam se na info@event.ba.",
        backToHome: "Natrag na početnu",
        close: "Zatvori",
        warning: "Nećete primiti ulaznice ako napustite ili osvježite ovu stranicu!",
      };

  useEffect(() => {
    // Trigger showing "sendingTickets" state after a delay
    const timeout = setTimeout(() => {
      setSendingTickets(true); // Set sendingTickets to true after 17 seconds
    }, 13000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <div className="global-loader">
        <div className="wrapper">
          <div className="big-flex">
            {!sendingTickets ? (
              <Bars
                height="80"
                width="80"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="loader"
              />
            ) : (
              <div className="global-loader-text">
                {text.wait}
                <br />
                {text.checkEmail}
                <div className="loader-wrapper">
                  <Bars
                    height="80"
                    width="80"
                    color="#fff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="check-email">
            <Link to="/" style={{ color: "#fff" }}>
              {text.backToHome}
            </Link>
          </div>
        </div>
        <button
          disabled={!sendingTickets}
          onClick={() => {
            setLoading(false);
          }}
        >
          {!sendingTickets ? text.wait : text.close}
        </button>
      </div>
    </>
  );
};

export default LoaderBuyPage;
