import { CheckCheck, X } from "lucide-react";
import { MyTickets } from "../profile-page/my-ticket/MyTickets";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { Tickets, CreditCard, MailQuestion, DollarSign, Download } from "lucide-react";

const OrderDisplay = ({ paymentReq, language }) => {
  const userId = useSelector((state) => state.userState.user);
  const [orderData, setOrderData] = useState(null);
  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/users/id/${userId}`
      );
      setOrderData(
        response.data.buy_history.filter(
          (order) => order.event.order_number === paymentReq.orderNumber
        )
      );
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const displayText = {
    status: language === "hr" ? "Status" : "Status",
    ticketsReserved: language === "hr" ? "Rezervirane ulaznice" : "Reserved tickets",
    ticketsDelivered: language === "hr" ? "Dostavljene ulaznice" : "Delivered tickets",
    totalPrice: language === "hr" ? "Ukupna cijena" : "Total price",
    downloadTickets: language === "hr" ? "Preuzmi ulaznice" : "Download tickets",
    ticketDownloadP1:
      language === "hr"
        ? `Ulaznice također možete direktno preuzeti i na `
        : "You can download your tickets directly from your profile too at ",
    ticketDownoadP2: language === "hr" ? `pod rubrikom "Moje ulaznice"` : `under "My tickets"`,
  };

  const statusText = {
    pending: language === "hr" ? "U tijeku" : "Pending",
    approved: language === "hr" ? "Odobreno" : "Approved",
    declined: language === "hr" ? "Odbijeno" : "Declined",
  };

  return (
    <div className="order-container">
      <div className="order-display">
        <div className="flex-icons-container">
          <CreditCard />
          {displayText?.status}:{" "}
          <span
            style={{
              color:
                paymentReq?.status === "approved"
                  ? "green"
                  : paymentReq?.status === "pending"
                  ? "yellow"
                  : "red",
            }}
          >
            {statusText[paymentReq?.status]}
          </span>
        </div>
        <div className="flex-icons-container">
          <Tickets />
          {displayText?.ticketsReserved}:{" "}
          {paymentReq?.concertUpdated ? <CheckCheck style={{ color: "green" }} /> : <X />}
        </div>
        <div className="flex-icons-container">
          <MailQuestion />
          {displayText?.ticketsDelivered}:{" "}
          {paymentReq?.tickets_delivered ? <CheckCheck style={{ color: "green" }} /> : <X />}
        </div>
        <div className="flex-icons-container">
          <DollarSign />
          <span>
            {displayText?.totalPrice}: {paymentReq?.ticketGenData?.totalAmount} <small>BAM</small>
          </span>
        </div>
      </div>
      <div className="ticket-catcher">
        <div className="order-header">
          <Download />
          <h4>{displayText?.downloadTickets}</h4>
        </div>
        {orderData && paymentReq?.concertUpdated && <MyTickets buy_history={orderData} />}
        <span style={{ textAlign: "start" }}>
          {displayText?.ticketDownloadP1}{" "}
          <a style={{ color: "blue", fontWeight: "bold", margin: "0px auto" }} href="/profile">
            {language === "hr" ? "profilu" : "profile"}
          </a>{" "}
          {displayText?.ticketDownoadP2}.
        </span>
      </div>
    </div>
  );
};

export default OrderDisplay;
