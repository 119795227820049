import React, { useEffect, useState } from "react";
import { AddOrganizer } from "../organize-event-page/AddOrganizer";
import axios from "axios";
import SponsorModal from "../organize-event-page/SponsorModal";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import { Editor } from "@tinymce/tinymce-react";
import tinyMCEConfig from "../../../components/helper/tinyConfig";
import { useDispatch, useSelector } from "react-redux";

import { setLoginIsOpen } from "../../../store/loginSlice";
import { formatDateToCET } from "../../../components/helper/formatDateToCET";

export const UpdateEventContainer = ({ concertData }) => {
  const [organizer, setOrganizer] = useState(concertData?.organizer);
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);
  const [credentialsRequired, setCredentialsRequired] = useState(concertData?.credentials_required);
  const [hidden, setHidden] = useState(concertData?.hidden);
  const [onSale, setOnSale] = useState(concertData?.on_sale);
  const dispatch = useDispatch();

  // Function to format date and time to CET (Central European Time)

  const [editedConcertData, setEditedConcertData] = useState(concertData);

  useEffect(() => {
    const initialTimeValue = formatDateToCET(concertData?.time_of_event);
    const initialSaleTimeValue = formatDateToCET(concertData?.sale_start || new Date());
    const editedConcert = {
      ...concertData,
      time_of_event: initialTimeValue,
      sale_start: initialSaleTimeValue,
      max_tickets_per_user: concertData?.max_tickets_per_user || "", // Ensure max_tickets_per_user is defined
    };

    setEditedConcertData(editedConcert);
    setSponsorNames(editedConcert.sponsors || []);
    setCredentialsRequired(editedConcert.credentials_required || false);
    setHidden(editedConcert.hidden || false);
    setOnSale(editedConcert.on_sale);
    setOrganizer(editedConcert.organizer);
  }, [concertData]);

  const [existingSponsors, setExistingSponsors] = useState([]);

  const [sponsors, setSponsors] = useState([]);
  const [sponsorNames, setSponsorNames] = useState(editedConcertData.sponsors || []); // Initialize after editedConcertData is defined
  const [isModalOpen, setModalOpen] = useState(false);
  // Initialize state to hold the edited data, including "time_of_event"

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  // Handle changes to form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // If the field is "time_of_event," no need to change the format
    if (name === "time_of_event" || name === "sale_start") {
      setEditedConcertData({
        ...editedConcertData,
        [name]: formatDateToCET(value),
      });
    } else if (name.startsWith("place.")) {
      const placeField = name.split(".")[1];
      setEditedConcertData({
        ...editedConcertData,
        place: {
          ...editedConcertData.place,
          [placeField]: value,
        },
      });
    } else if (name === "type") {
      const types = value.split(",").map((type) => type.trim());
      setEditedConcertData({
        ...editedConcertData,
        type: types,
      });
    } else {
      // For other fields, update as usual
      setEditedConcertData({
        ...editedConcertData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    fetchSponsors();
  }, []);

  const fetchSponsors = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/helper/sponsors`);
      setExistingSponsors(response.data);
    } catch (error) {}
  };

  function getChangedAttributes(originalData, editedData) {
    const changedAttributes = {};

    for (const key in editedData) {
      if (originalData[key] !== editedData[key]) {
        changedAttributes[key] = editedData[key];
      }
    }

    return changedAttributes;
  }

  const changedAttributes = getChangedAttributes(concertData, editedConcertData);

  const id = concertData._id;
  // Handle form submission (update the database)
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/v1/concerts/update_event",
        {
          id,
          data: {
            ...changedAttributes,
            sponsors: sponsorNames,
            organizer: organizer,
            credentials_required: credentialsRequired,
            hidden: hidden,
            on_sale: onSale,
            time_of_event: new Date(editedConcertData.time_of_event),
            sale_start: new Date(editedConcertData.sale_start),
          },
          userId,
          token,
        }
      );
      toast.success(response.data.message, toastSetup("bottom-center", 1500));
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setLoginIsOpen(true));
      }

      toast.error(error.response.data.message, toastSetup("top-center", 3000));
    }
  };

  return (
    concertData && (
      <div>
        <form onSubmit={handleSubmit}>
          <div className="input-row">
            <input
              type="text"
              id="performer_name"
              name="performer_name"
              value={editedConcertData.performer_name}
              onChange={handleInputChange}
            />

            <input
              type="datetime-local"
              id="time_of_event"
              name="time_of_event"
              value={editedConcertData.time_of_event}
              onChange={handleInputChange}
            />
          </div>
          <div className="input-row">
            <AddOrganizer setOrganizer={setOrganizer} organizer={organizer} />
            <select
              name="type"
              value={
                editedConcertData.type[0] !== "suggested"
                  ? editedConcertData.type[0]
                  : editedConcertData.type[1]
              }
              onChange={handleInputChange}
              className="event-input"
            >
              <option value="concert">Koncert</option>
              <option value="festival">Festival</option>
              <option value="theaters">Predstava</option>
              <option value="sport">Sport</option>
              <option value="other">Ostalo</option>
            </select>
          </div>
          <div className="input-row">
            <div className="credentials-required-wrapper">
              <input
                checked={credentialsRequired}
                type="checkbox"
                onChange={() => setCredentialsRequired(!credentialsRequired)}
                id="credentialsRequired"
                name="credentialsRequired"
                className="credentials-required"
              />

              <label className="label-checkbox">Zahtjevaj osobne podatke na ulaznici</label>
            </div>
            <div className="credentials-required-wrapper">
              <input
                checked={!onSale}
                type="checkbox"
                name="on_sale"
                onChange={() => setOnSale(!onSale)}
                id="on_sale"
                className="credentials-required"
              />

              <label className="label-checkbox">Zaustavi prodaju</label>
            </div>
          </div>
          <div className="city">
            <input
              className="one"
              type="text"
              id="place.country"
              name="place.country"
              value={editedConcertData.place.country}
              onChange={handleInputChange}
            />

            <input
              className="two"
              type="text"
              id="place.city"
              name="place.city"
              value={editedConcertData.place.city}
              onChange={handleInputChange}
            />

            <input
              className="three"
              type="text"
              id="place.place"
              name="place.place"
              value={editedConcertData.place.place}
              onChange={handleInputChange}
            />
          </div>
          <div className="sponsor-row">
            <button type="button" className="sponsor-btn" onClick={toggleModal}>
              Odaberi sponzore
            </button>
            <ul className="sponsors-ul">
              {sponsorNames[0] !== undefined ? (
                sponsorNames.map((e, i) => {
                  return <li key={i}>{e.split(".")[0]}</li>;
                })
              ) : (
                <li className="not-selected-sponsor">
                  Sponzori nisu odabrani <span>*</span>
                </li>
              )}
            </ul>

            <div className="credentials-required-wrapper" style={{ marginLeft: "20px" }}>
              <input
                checked={hidden}
                type="checkbox"
                onChange={() => setHidden(!hidden)}
                id="hidden"
                className="credentials-required"
              />

              <label className="label-checkbox">Sakrij događaj s naslovne stranice</label>
            </div>
          </div>
          <div
            className="input-row"
            style={{
              display: "flex",
              alignItems: "center",
              width: "95%",
              margin: "0 auto",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <h6>Broj ulaznica po korisniku:</h6>
              <input
                style={{ width: "50px", margin: "0 10px", textAlign: "center" }}
                type="number"
                id="max_tickets_per_user"
                name="max_tickets_per_user"
                placeholder="Broj"
                value={
                  editedConcertData && editedConcertData.max_tickets_per_user !== undefined
                    ? editedConcertData.max_tickets_per_user
                    : null
                }
                onChange={handleInputChange}
              />{" "}
            </div>
            <h6>Početak online prodaje</h6>
            <input
              type="datetime-local"
              id="sale_start"
              name="sale_start"
              value={editedConcertData.sale_start}
              onChange={handleInputChange}
              style={{ width: "min(170px,30%)", margin: "0 10px" }}
            />
          </div>
          <SponsorModal
            sponsors={sponsors}
            setSponsors={setSponsors}
            isOpen={isModalOpen}
            existingSponsors={existingSponsors}
            toggleModal={toggleModal}
            setSponsorNames={setSponsorNames}
            sponsorNames={sponsorNames}
          />
          <div className="description-wrapper">
            <h6>Opis događaja</h6>
            <Editor
              apiKey={tinyMCEConfig.apiKey}
              value={String(editedConcertData.description || "")}
              init={tinyMCEConfig}
              onEditorChange={(content) =>
                setEditedConcertData({
                  ...editedConcertData,
                  description: content,
                })
              }
            />
          </div>
          <button type="submit">Uredi događaj</button>
        </form>
      </div>
    )
  );
};
