const bosnianCities = [
  "Bosanski Petrovac",
  "Bihać",
  "Bosansko Grahovo",
  "Bužim",
  "Cazin",
  "Dobretići",
  "Domaljevac-Šamac",
  "Drvar",
  "Jajce",
  "Ključ",
  "Odžak",
  "Sanski Most",
  "Velika Kladuša",
  "Banja Luka",
  "Čelinac",
  "Derventa",
  "Gradiška",
  "Jezero",
  "Ribnik",
  "Kneževo",
  "Kotor Varoš",
  "Kozarska Dubica",
  "Krupa na Uni",
  "Laktaši",
  "Mrkonjić Grad",
  "Novi Grad",
  "Petrovac-Drinić",
  "Prijedor",
  "Prnjavor",
  "Srbac",
  "Kostajnica",
  "Brod",
  "Istočni Drvar",
  "Oštra Luka",
  "Šamac",
  "Šipovo",
  "Vukosavlje",
  "Bugojno",
  "Čapljina",
  "Čitluk",
  "Donji Vakuf",
  "Glamoč",
  "Gornji Vakuf - Uskoplje",
  "Grude",
  "Jablanica",
  "Konjic",
  "Kupres",
  "Livno",
  "Ljubuški",
  "Grad Mostar",
  "Neum",
  "Novi Travnik",
  "Posušje",
  "Prozor-Rama",
  "Ravno",
  "Stolac",
  "Široki Brijeg",
  "Tomislavgrad",
  "Travnik",
  "Berkovići",
  "Bileća",
  "Gacko",
  "Kupres",
  "Ljubinje",
  "Nevesinje",
  "Trebinje",
  "Istočni Mostar",
  "Breza",
  "Busovača",
  "Centar Sarajevo",
  "Foča",
  "Fojnica",
  "Goražde",
  "Hadžići",
  "Ilidža",
  "Ilijaš",
  "Kakanj",
  "Kiseljak",
  "Kreševo",
  "Novi Grad",
  "Novo Sarajevo",
  "Olovo",
  "Pale",
  "Stari Grad Sarajevo",
  "Vareš",
  "Visoko",
  "Vitez",
  "Vogošća",
  "Zavidovići",
  "Zenica",
  "Žepče",
  "Trnovo",
  "Čajniče",
  "Kalinovik",
  "Istočno Novo Sarajevo",
  "Pale",
  "Rogatica",
  "Rudo",
  "Sokolac",
  "Foča",
  "Istočna Ilidža",
  "Istočni Stari Grad",
  "Novo Goražde",
  "Trnovo",
  "Višegrad",
  "Banovići",
  "Čelić",
  "Doboj istok",
  "Doboj jug",
  "Gračanica",
  "Gradačac",
  "Kalesija",
  "Kladanj",
  "Lukavac",
  "Maglaj",
  "Orašje",
  "Sapna",
  "Srebrenik",
  "Teočak",
  "Tešanj",
  "Tuzla",
  "Usora",
  "Živinice",
  "Bijeljina",
  "Bratunac",
  "Doboj",
  "Han Pijesak",
  "Lopare",
  "Milići",
  "Modriča",
  "Osmaci",
  "Pelagićevo",
  "Petrovo",
  "Srebrenica",
  "Donji Žabar",
  "Šekovići",
  "Teslić",
  "Ugljevik",
  "Vlasenica",
  "Zvornik",
  "Brčko",
];

export default bosnianCities;
