import React from "react";
import Hero from "./hero/Hero";
import { ThisWeek } from "../../components/this-week/ThisWeek";
import withSEO from "../../withSEO";

export const Landing = () => {
  return (
    <div>
      <Hero />
      <ThisWeek heading="Ovaj tjedan" />
    </div>
  );
};

// Wrap the Landing component with the SEO HOC
const LandingWithSEO = withSEO(
  Landing,
  "Event.ba",
  "Vodeći portal za prodaju ulaznica i promociju događaja u regiji. Brza kupovina, autentične ulaznice, nezaboravna iskustva!",
  "koncerti, koncerti sarajevo, koncerti bosna, koncerti bih, dom mladih sarajevo, predstave sarajevo, aca lukas koncerti, koncerti 2024, predstave 2024, teatar, pozorište, pozorište ulaznice, pozorište bih, pozorište sarajevo, ulaznice bosna ukrajina, ulaznice nogomet, ulaznice bosna, ulaznice bih, reprezentacija bih, nogometni savez bih, cijena ulaznice, karte za koncert, karte za koncert ace lukasa, cena karte za koncert ace lukasa, aca lukas karte za koncert, aca lukas 2024, prodaja ulaznica, prodaja ulaznica bih, prodaja ulaznica bosna, prodaja ulaznica Sarajevo, prodaja ulaznica sarajevo, prodaja ulaznica sasa matic, prodaja ulaznica bih, stadion bilino polje, grbavica stadion, grbavica, online ulaznice, online ulaznice bosna 2024, online ulaznice bih"
);

export default LandingWithSEO; // Export the component with SEO applied
