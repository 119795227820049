import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { toastSetup } from "../../../functions/toastSetup";
import AddTicketImageMapper from "./ImageMapper";
import SeatDisplay from "./SeatDisplay";

import { Undo2 } from "lucide-react";

export const AddTicketTheater = ({ placeData, tickets, setTickets, freeSale, onlineSale }) => {
  const [groundPlanImg, setImg] = useState(null);
  const [modalWindow, setModalWindow] = useState(false);
  const [selectedZoneData, setSelectedZoneData] = useState();
  const [selectedZone, setSelectedZone] = useState([]);
  const [price, setPrice] = useState("");
  const [type, setType] = useState("");
  const [ticketNum, setTicketNum] = useState("");

  // Load ground image
  useEffect(() => {
    const loadImage = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/static/ground-plans/${placeData.ground_plan}`
        );

        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);

        // Create an image element to calculate its dimensions
        const imgElement = new Image();

        // Set up the onload event handler
        imgElement.onload = () => {
          setImg({
            src: imageUrl,
            width: imgElement.width,
            height: imgElement.height,
          });
        };

        // Set the src AFTER defining the onload handler
        imgElement.src = imageUrl;
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [placeData.name]);

  // Zone click
  function handleZoneClick(e, zoneData) {
    setModalWindow(true);
    setSelectedZoneData(zoneData);
    if (document.querySelector(".highlighted"))
      document.querySelector(".highlighted").classList.remove("highlighted");
    e.classList.add("highlighted");

    let zoneKey = zoneData[0];
    const onlineSaleZone = onlineSale.zones[zoneKey];
    const hasSeatsInOnlineSale =
      onlineSaleZone.rows && Object.values(onlineSaleZone.rows).some((row) => row.seats.length > 0);

    const isAlreadyOnSale = onlineSaleZone?.on_sale || hasSeatsInOnlineSale;

    const zoneFreeSaleData = zoneData[1];

    if (isAlreadyOnSale && onlineSaleZone?.rows) {
      toast.info(
        `${zoneKey} zona je u online prodaji! Zaustavite online prodaju za ovu kategoriju.`,
        toastSetup("top-center", 3000)
      );
    }

    const ticketToSave = onlineSaleZone?.rows
      ? {
          categoryName: zoneKey,
          ticketsNum: calculateTicketsNum(onlineSaleZone.rows, isAlreadyOnSale),
          ticketType: zoneFreeSaleData.name,
          ticketPrice: Number(zoneFreeSaleData.price),
          rows: isAlreadyOnSale
            ? onlineSaleZone.rows
            : Object.fromEntries(
                Object.entries(onlineSaleZone.rows).map(([rowKey, row]) => [
                  rowKey,
                  {
                    total_seats: row.total_seats,
                    seats: Array.from({ length: row.total_seats }, (_, i) => i + 1),
                  },
                ])
              ),
        }
      : {
          categoryName: zoneKey,
          ticketsNum: ticketNum,
          ticketType: zoneFreeSaleData.name,
          ticketPrice: Number(zoneFreeSaleData.price),
        };
    setTickets((tickets) =>
      tickets.find((ticket) => ticket.categoryName === zoneKey)
        ? tickets
        : [...tickets, ticketToSave]
    );
  }

  function saveZones(e) {
    e.preventDefault();

    const rowsPrice = document.querySelector(".rows-price").value;
    const rowsCategory = document.querySelector(".rows-category").value;

    if (rowsPrice === "" || rowsCategory === "" || !selectedZone[0]) {
      toast.warn(
        "Molimo unesite cijenu, kategoriju i odaberite barem jednu zonu.",
        toastSetup("top-right", 3000)
      );
      return;
    }

    selectedZone.forEach((zoneKey) => {
      const onlineSaleZone = onlineSale.zones[zoneKey];
      const isAlreadyOnSale = onlineSaleZone?.on_sale;

      const ticketToSave = onlineSaleZone?.rows
        ? {
            categoryName: zoneKey,
            ticketType: rowsCategory,
            ticketPrice: rowsPrice,
            ticketsNum: calculateTicketsNum(onlineSaleZone.rows, isAlreadyOnSale),
            rows: isAlreadyOnSale
              ? onlineSaleZone.rows
              : Object.fromEntries(
                  Object.entries(onlineSaleZone.rows).map(([rowKey, row]) => [
                    rowKey,
                    { total_seats: row.total_seats },
                  ])
                ),
          }
        : {
            categoryName: zoneKey,
            ticketType: rowsCategory,
            ticketsNum: ticketNum,
            ticketPrice: rowsPrice,
          };

      setTickets((tickets) => {
        const existingTicketIndex = tickets.findIndex((ticket) => ticket.categoryName === zoneKey);
        if (existingTicketIndex !== -1) {
          const updatedTickets = [...tickets];
          updatedTickets[existingTicketIndex] = {
            ...updatedTickets[existingTicketIndex],
            ...ticketToSave,
          };
          return updatedTickets;
        }

        return [...tickets, ticketToSave];
      });
    });

    toast.success(
      "Uspješno ste dodali cijenu i kategoriju za odabrane zone.",
      toastSetup("top-right", 3000)
    );
  }

  // Extracted function to calculate ticketsNum
  function calculateTicketsNum(rows, isAlreadyOnSale) {
    return isAlreadyOnSale
      ? Object.values(rows).reduce((acc, row) => acc + row.seats.length, 0)
      : Object.values(rows).reduce((acc, row) => acc + parseInt(row.total_seats), 0);
  }

  const handleCheckboxChange = (zoneKey) => {
    const updatedSelectedZone = [...selectedZone];

    if (updatedSelectedZone.includes(zoneKey)) {
      // If the zoneKey is already in the selectedZone array, remove it
      const index = updatedSelectedZone.indexOf(zoneKey);
      if (index !== -1) {
        updatedSelectedZone.splice(index, 1);
      }
    } else {
      // If the zoneKey is not in the selectedZone array, add it
      updatedSelectedZone.push(zoneKey);
    }

    setSelectedZone(updatedSelectedZone);
  };

  // Function to select all checkboxes
  const selectAll = () => {
    const allZoneKeys = Object.keys(placeData.zones);

    if (selectedZone.length === allZoneKeys.length) {
      // If all are already selected, unselect all
      setSelectedZone([]);
    } else {
      setSelectedZone(allZoneKeys);
    }
  };

  // Function to unselect all checkboxes
  const unselectAll = () => {
    setSelectedZone([]);
  };

  const handleCloseModal = () => {
    setModalWindow(false);
    setTickets((prevTickets) =>
      prevTickets.filter((ticket) => ticket.categoryName !== selectedZoneData[0])
    );
    document
      .querySelectorAll(".highlighted")
      .forEach((element) => element.classList.remove("highlighted"));
  };

  const handleBlurClick = () => {
    setModalWindow(false);
    document
      .querySelectorAll(".highlighted")
      .forEach((element) => element.classList.remove("highlighted"));
  };

  return (
    groundPlanImg && (
      <>
        {modalWindow ? (
          <>
            <div className="add-ticket-modal">
              <div className="modal-wrapper">
                <h6 style={{ textAlign: "center", margin: 0, marginRight: "30px" }}>
                  Zona: {selectedZoneData[0]}
                </h6>

                <Undo2
                  className="close-modal"
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
                <label>Cijena zone</label>
                <input
                  type="number"
                  value={
                    tickets.find((ticket) => ticket.categoryName === selectedZoneData[0])
                      ?.ticketPrice
                  }
                  placeholder="Unesite cijenu (BAM)"
                  onChange={(e) => {
                    setTickets((prevTickets) =>
                      prevTickets.map((ticket) => {
                        if (ticket.categoryName === selectedZoneData[0]) {
                          return {
                            ...ticket,
                            ticketPrice: e.target.value,
                          };
                        }
                        return ticket;
                      })
                    );
                  }}
                />

                <label>Tip ulaznice</label>
                <input
                  type="text"
                  placeholder="Tip ulaznice"
                  value={
                    tickets.find((ticket) => ticket.categoryName === selectedZoneData[0])
                      ?.ticketType
                  }
                  onChange={(e) => {
                    setTickets((prevTickets) =>
                      prevTickets.map((ticket) => {
                        if (ticket.categoryName === selectedZoneData[0]) {
                          return {
                            ...ticket,
                            ticketType: e.target.value,
                          };
                        }
                        return ticket;
                      })
                    );
                  }}
                />
                {selectedZoneData && !selectedZoneData[1]?.rows && (
                  <>
                    <label>Broj ulaznica</label>
                    <input
                      placeholder="Broj ulaznica"
                      type="number"
                      value={
                        tickets.find((ticket) => ticket.categoryName === selectedZoneData[0])
                          ?.ticketsNum
                      }
                      onChange={(e) => {
                        setTickets((prevTickets) =>
                          prevTickets.map((ticket) => {
                            if (ticket.categoryName === selectedZoneData[0]) {
                              return {
                                ...ticket,
                                ticketsNum: e.target.value,
                              };
                            }
                            return ticket;
                          })
                        );
                      }}
                    />
                  </>
                )}

                {selectedZoneData[1]?.rows && (
                  <SeatDisplay
                    selectedZoneData={selectedZoneData}
                    tickets={tickets}
                    setTickets={setTickets}
                    onlineSale={onlineSale}
                  />
                )}
              </div>
            </div>
            <div onClick={() => handleBlurClick()} className="blur"></div>
          </>
        ) : (
          ""
        )}
        {groundPlanImg && (
          <div className="organize-event-plan-wrapper">
            <div
              id="tooltip"
              display="none"
              style={{ position: "absolute", display: "none" }}
            ></div>
            <AddTicketImageMapper
              cb={(editor) => {
                editor.loadImage(groundPlanImg.src); // Load the URL directly
                editor.polygon();
              }}
              options={{
                width: groundPlanImg.width,
                height: groundPlanImg.height,
              }}
              tickets={tickets}
              handleZoneClick={handleZoneClick}
              freeSale={freeSale}
              onlineSale={onlineSale}
            />
            <div className="select-all-seats-wrapper">
              <p style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                Odredi cijenu i tip za više redova
              </p>

              <div className="organize-zones-wrapper">
                {Object.entries(placeData.zones).map(([zoneKey, value], i) => {
                  const isChecked = selectedZone.includes(zoneKey);

                  return (
                    <div className="selling-row" key={i}>
                      <p>Zona {zoneKey}</p>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => handleCheckboxChange(zoneKey)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="zones-input-wrapper">
                <input className="rows-price" type="number" placeholder="Cijena (BAM)" />
                <input className="rows-category" type="text" placeholder="Tip" />
                <div className="button-wrapper">
                  {selectedZone.length < 1 ? (
                    <button type="button" onClick={selectAll}>
                      Odaberi sve
                    </button>
                  ) : (
                    <button type="button" onClick={unselectAll}>
                      Poništi odabir
                    </button>
                  )}

                  <button
                    type="button"
                    onClick={(e) => {
                      saveZones(e);
                    }}
                  >
                    Spremi
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
};
