import React from "react";
import ThankYouImg from "../../assets/images/thank_you.png";
import { Link, useLocation } from "react-router-dom";

export const ThankYou = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const successMessage = searchParams.get("successMessage");

  return (
    <div className="container thank-you-container">
      <img src={ThankYouImg} alt="Thank You" />
      <h3>{successMessage}</h3>
      <Link to="/">Natrag na početnu</Link>
    </div>
  );
};
