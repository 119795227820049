export const formatDateToCET = (dateTimeString) => {
  if (!dateTimeString) {
    console.error("dateTimeString is null or undefined");
    return null;
  }

  try {
    const date = new Date(dateTimeString);
    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateTimeString}`);
      return null;
    }

    const lastMarchSunday = new Date(date.getFullYear(), 2, 31 - ((date.getDay() + 5) % 7)); // March has index 2
    const lastOctoberSunday = new Date(date.getFullYear(), 9, 31 - ((date.getDay() + 5) % 7)); // October has index 9

    const isDST = date > lastMarchSunday && date < lastOctoberSunday;
    const ceeOffsetMinutes = isDST ? 120 : 60; // CET is UTC+1 in winter and UTC+2 in summer
    date.setMinutes(date.getMinutes() + ceeOffsetMinutes);

    const result = date.toISOString().slice(0, 16); // Format as "YYYY-MM-DDTHH:MM"
    return result;
  } catch (error) {
    console.error(`Error formatting date: ${error}`);
    return null;
  }
};
