import React, { useEffect, useState } from "react";
import SearchInput from "../../hero/main-search/SearchInput";
import axios from "axios";
import { SearchNavLink } from "./SearchNavLink";
import sortByTime from "../../../../functions/sortByTimeOfEvent";
import { useSelector } from "react-redux";

const languageMap = {
  hr: {
    suggested: "Preporuka",
    concert: "Koncerti",
    festival: "Festivali",
    sport: "Sport",
    theaters: "Predstave",
    other: "Ostalo",
  },
  en: {
    suggested: "Suggested",
    concert: "Concerts",
    festival: "Festivals",
    sport: "Sports",
    theaters: "Theaters",
    other: "Other",
  },
};

const MainSearchNav = ({ setEvents, setIsLoading }) => {
  const [category, setCategory] = useState("suggested");
  const language = useSelector((state) => state.userState.language);
  const languageStrings = languageMap[language];
  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      if (category) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/concerts/type/${category}`
        );

        setEvents(sortByTime(response.data));
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [category]);

  // Set active link
  const handleClick = (category) => {
    setCategory(category);
  };

  return (
    <div className="search-mini-nav">
      <ul className="search-nav-left">
        <SearchNavLink
          isActive={category === "suggested" ? "searchActive" : ""}
          handleClick={handleClick}
          content={languageStrings.suggested}
          category="suggested"
        />
        <SearchNavLink
          isActive={category === "concert" ? "searchActive" : ""}
          handleClick={handleClick}
          content={languageStrings.concert}
          category="concert"
        />
        <SearchNavLink
          isActive={category === "festival" ? "searchActive" : ""}
          handleClick={handleClick}
          content={languageStrings.festival}
          category="festival"
        />
        <SearchNavLink
          isActive={category === "sport" ? "searchActive" : ""}
          handleClick={handleClick}
          content={languageStrings.sport}
          category="sport"
        />
        <SearchNavLink
          isActive={category === "theaters" ? "searchActive" : ""}
          handleClick={handleClick}
          content={languageStrings.theaters}
          category="theaters"
        />

        <SearchNavLink
          isActive={category === "other" ? "searchActive" : ""}
          handleClick={handleClick}
          content={languageStrings.other}
          category="other"
        />
      </ul>
      <ul className="search-nav-right">
        <li>
          <SearchInput
            setEvents={setEvents}
            setCategory={setCategory}
            setIsLoading={setIsLoading}
          />
        </li>
      </ul>
    </div>
  );
};

export default MainSearchNav;
