import React from "react";

export const TheaterModal = ({ selectedZoneData, theaterZones, takeSeat, activeCardIndex }) => {
  const zoneData = selectedZoneData && selectedZoneData[1];
  const onSale = zoneData?.on_sale ?? true;
  const orientation = zoneData && zoneData.orientation;
  const { rows = "column-reverse", seats } = orientation || {};

  if (!selectedZoneData || !theaterZones || !theaterZones[selectedZoneData[0]]) {
    return null;
  }

  return (
    <div className="modal">
      <span className="zone-info">
        {onSale ? (
          <>
            Zona: {selectedZoneData[0]} - Cijena: {zoneData.price} <small>BAM</small>
          </>
        ) : (
          "Zona nije na prodaju"
        )}
      </span>
      {!!onSale && selectedZoneData[1]?.rows ? (
        <div className="rows-container" style={{ flexDirection: rows }}>
          {Object.entries(theaterZones[selectedZoneData[0]].rows).map(([key, value]) => {
            const reservedSeats = value.reserved_seats || {};

            return (
              <div key={key} className="seats-container" style={{ flexDirection: seats || "row" }}>
                <div className="row-info">Red {key}</div>
                {Array.from({ length: Number(value.total_seats) }, (_, i) => {
                  const seatNumber = i + 1;
                  const isSeatReserved = seatNumber in reservedSeats;
                  const reservedTicketID = isSeatReserved
                    ? reservedSeats[seatNumber].ticketID
                    : null;

                  return (
                    <div
                      key={`${i}-${key}-${seatNumber}`}
                      className={
                        value.seats.includes(seatNumber)
                          ? isSeatReserved
                            ? "reserved"
                            : "free"
                          : "sold"
                      }
                      onClick={() => {
                        if (isSeatReserved) {
                          console.log("Seat is reserved");
                        } else {
                          takeSeat(seatNumber, activeCardIndex + 1, key);
                        }
                      }}
                    >
                      {reservedTicketID || seatNumber}
                    </div>
                  );
                })}
                <div className="row-info">Red {key}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="sold"></div>
      )}
    </div>
  );
};
