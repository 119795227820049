import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../store/userSlice";

import { Globe } from "lucide-react";

export const LanguageToggler = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.userState.language);

  const toggleLanguage = (language) => {
    if (language === "hr") {
      localStorage.setItem("language", "en");
      dispatch(setLanguage("en"));
    } else {
      dispatch(setLanguage("hr"));
      localStorage.setItem("language", "hr");
    }
  };
  return (
    <div
      onMouseDown={() => toggleLanguage(language)}
      style={{
        cursor: "pointer",
        padding: "2px 5px",
        borderRadius: "10px",
        border: "2px dashed #ddd",
        outline: "none",
        display: "flex",
        alignItems: "center",
        gap: "3px",
        color: "#fff",
      }}
    >
      <Globe size={18} color="#eee" />
      <span style={{ fontSize: "0.9rem" }}>{language === "hr" ? "Hrvatski" : "English"}</span>
    </div>
  );
};
