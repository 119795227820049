import React from "react";

export const Theater = () => {
  return (
    <div>
      {" "}
      <h6>Nije dostupan alat.</h6>
    </div>
  );
};
