import React, { useState } from "react";

import { ToggleLeft, ToggleRight } from "lucide-react";

const SeatDisplay = ({ selectedZoneData, tickets, setTickets, onlineSale }) => {
  const [isAllSelected, setIsAllSelected] = useState(false);

  const orientation = selectedZoneData[1].orientation;

  const realTimeTickets = tickets.find((ticket) => ticket.categoryName === selectedZoneData[0]);

  const { rows = "column-reverse", seats } = orientation || {};

  let zoneKey = selectedZoneData[0];
  const onlineSaleZone = onlineSale.zones[zoneKey];

  const hasSeatsInOnlineSale =
    onlineSaleZone.rows && Object.values(onlineSaleZone.rows).some((row) => row.seats.length > 0);

  const isAlreadyOnSale = onlineSaleZone?.on_sale || hasSeatsInOnlineSale;

  const toggleSeats = ({ row, seat }) => {
    setTickets((prevTickets) => {
      const updatedTickets = prevTickets.map((zone) => {
        if (zone.categoryName === selectedZoneData[0]) {
          const updatedRows = { ...zone.rows };
          const updatedSeats = updatedRows[row].seats.includes(seat)
            ? updatedRows[row].seats.filter((seatNumber) => seatNumber !== seat)
            : [...updatedRows[row].seats, seat];

          updatedRows[row] = { ...updatedRows[row], seats: updatedSeats };
          return {
            ...zone,
            rows: updatedRows,
            ticketsNum: Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0),
          };
        }
        return zone;
      });
      return updatedTickets;
    });
  };

  const toggleRow = (row) => {
    setTickets((prevTickets) => {
      const updatedTickets = prevTickets.map((zone) => {
        const updatedRows = { ...zone.rows };
        const updatedSeats = updatedRows[row].seats.length
          ? []
          : isAlreadyOnSale
          ? selectedZoneData[1].rows[row].seats
          : Array.from({ length: Number(zone.rows[row].total_seats) }, (_, i) => i + 1);

        updatedRows[row] = { ...updatedRows[row], seats: updatedSeats };
        return {
          ...zone,
          rows: updatedRows,
          ticketsNum: Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0),
        };
      });
      return updatedTickets;
    });
  };

  const toggleAll = () => {
    setIsAllSelected(!isAllSelected);
    setTickets((prevTickets) => {
      const updatedTickets = prevTickets.map((zone) => {
        if (zone.categoryName === selectedZoneData[0]) {
          const updatedRows = { ...zone.rows };
          Object.entries(selectedZoneData[1].rows).forEach(([rowKey, rowValue]) => {
            updatedRows[rowKey] = {
              ...updatedRows[rowKey],
              seats: !isAllSelected
                ? []
                : isAlreadyOnSale
                ? selectedZoneData[1].rows[rowKey].seats
                : Array.from({ length: Number(zone.rows[rowKey].total_seats) }, (_, i) => i + 1),
            };
          });

          return {
            ...zone,
            rows: updatedRows,
            ticketsNum: Object.values(updatedRows).reduce((acc, row) => acc + row.seats.length, 0),
          };
        }
        return zone;
      });
      return updatedTickets;
    });
  };

  return (
    <div className="rows-container" style={{ flexDirection: rows }}>
      <div onClick={toggleAll} className="row-toggler">
        {isAllSelected ? <ToggleLeft size={32} /> : <ToggleRight size={32} />}
      </div>
      {Object.entries(realTimeTickets.rows).map(([key, value]) => {
        return (
          <div key={key} className="seats-container" style={{ flexDirection: seats || "row" }}>
            <div className="row-info" onClick={() => toggleRow(key)}>
              Red {key}
            </div>
            {Array.from({ length: Number(value.total_seats) }, (_, i) => {
              const seatNumber = i + 1;
              const notPrinted = selectedZoneData[1].rows[key].seats.includes(seatNumber);
              const isSold = value.seats.includes(seatNumber);
              let className = "sold";
              if (notPrinted) className = "free";
              if (isSold) className = "reserved";

              return (
                <div
                  key={`${i}-${key}-${seatNumber}`}
                  className={className}
                  onClick={() => toggleSeats({ row: key, seat: seatNumber })}
                >
                  {seatNumber}
                </div>
              );
            })}
            <div className="row-info" onClick={() => toggleRow(key)}>
              Red {key}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SeatDisplay;
