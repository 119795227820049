const EntranceCard = ({ entrance, total, maxScans }) => {
  const progress = 100 - (total / maxScans) * 100;
  return (
    <div className="entrance-category-card">
      <strong>{entrance}</strong>
      <span>Skenirano: {total}</span>
      <div className="progress-bar-container">
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${progress}%` }} // Move the fill based on progress
          ></div>
        </div>
      </div>
    </div>
  );
};

export default EntranceCard;
