import React from "react";
import { Helmet } from "react-helmet";

const withSEO = (WrappedComponent, title, description, keywords) => {
  const WithSEO = (props) => {
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
        </Helmet>
        <WrappedComponent {...props} />
      </>
    );
  };

  return WithSEO;
};

export default withSEO;
