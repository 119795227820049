import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { toastSetup } from "../functions/toastSetup";
import { useSelector } from "react-redux";

const languageMap = {
  hr: {
    verifying: "Verifikacija u tijeku...",
    verificationSuccess: "Verifikacija uspješna!",
    verificationFailed: "Verifikacija nije uspjela!",
    alreadyVerified: "Već ste verificirani!",
  },
  en: {
    verifying: "Verifying...",
    verificationSuccess: "Verification successful!",
    verificationFailed: "Verification failed!",
    alreadyVerified: "You are already verified!",
  },
};

export const Verification = () => {
  const { verificationCode } = useParams();
  const navigate = useNavigate();

  const language = useSelector((state) => state.userState.language);
  const languageStrings = languageMap[language];

  useEffect(() => {
    const verifyUser = async () => {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/api/v1/users/verify/${verificationCode}`
        );

        setTimeout(() => {
          navigate("/");
          if (response.status === 208) {
            toast.info(languageStrings.alreadyVerified, toastSetup("top-right", 3000));
          } else toast.success(languageStrings.verificationSuccess, toastSetup("top-right", 3000));
        }, 1000);
      } catch (error) {
        setTimeout(() => {
          navigate("/");
          toast.error(languageStrings.verificationFailed, toastSetup("top-right", 3000));
        }, 1000);
      }
    };
    verifyUser();
  }, [verificationCode]);

  return (
    <div className="verification">
      <h3>{languageStrings.verifying}</h3>
      <div className="loader">
        <Bars
          height="80"
          width="80"
          color="#455cd9"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  );
};
