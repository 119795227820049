import React from "react";
import { setLoginIsOpen } from "../../store/loginSlice";
import { useDispatch } from "react-redux";

const TicketRestricted = ({ concertData, date, profileData }) => {
  const dispatch = useDispatch();
  return (
    <div className="single-page-container">
      <div className="single-page-top">
        <img
          src={
            concertData?.poster?.landscape
              ? `${process.env.REACT_APP_API_URL}/static/event-images/${concertData.poster.landscape}`
              : ""
          }
          alt="concertData.poster.landscape"
          loading="lazy"
        />

        <div className="cover-overlay"></div>
      </div>
      <div className="buy-container">
        <div className="left">
          <div className="top-buy-page">
            <div className="info">
              <h3>{concertData.performer_name}</h3>
              <p className="card-main-info">
                {date} - {concertData?.place?.city}, {concertData?.place?.place}
              </p>
            </div>
            <img
              className="info-buy-page-image"
              src={
                concertData?.poster?.landscape
                  ? `${process.env.REACT_APP_API_URL}/static/event-images/${concertData.poster.landscape}`
                  : ""
              }
              alt="concertData.poster.landscape"
            />
          </div>
          <h6 style={{ textAlign: "center", padding: "10px" }}>
            {profileData?.email ? (
              <>
                {concertData?.sale_start && new Date(concertData?.sale_start) > new Date()
                  ? " Događaj je trenutno dostupan samo u pretprodaji ili ste već kupili maksimalni broj ulaznica."
                  : "Već ste kupili maksimalni broj ulaznica."}
              </>
            ) : (
              <div>
                Niste prijavljeni!
                <button
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    padding: "3px 15px",
                    marginLeft: "10px",
                    background: "#f4cd46",
                  }}
                  onClick={() => dispatch(setLoginIsOpen(true))}
                >
                  Prijava
                </button>
              </div>
            )}
          </h6>
        </div>
        <div className="right">
          <img
            src={
              concertData?.poster?.landscape
                ? `${process.env.REACT_APP_API_URL}/static/event-images/${concertData.poster.landscape}`
                : ""
            }
            alt="concertData.poster.landscape"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default TicketRestricted;
