import React from "react";
import { ProfileEventCard } from "./ProfileEventCard";

export const MyTickets = ({ buy_history }) => {
  const open = buy_history?.length > 1 ? false : true;

  return (
    <div className="mytickets-container">
      {buy_history[0] !== undefined ? (
        buy_history
          .slice()
          .sort((a, b) => new Date(b.event.date) - new Date(a.event.date))
          .map((e, i) => {
            return <ProfileEventCard key={i} data={e} i={i} open={open} />;
          })
      ) : (
        <p className="no-bought-tickets-msg">Nemate kupljenih ulaznica do sada</p>
      )}
    </div>
  );
};
