import { TicketPlus, ShoppingCart } from "lucide-react";

const ScrollButtons = ({ addRef, payRef }) => {
  const scrollToAdd = () => {
    if (addRef.current) {
      // Scroll into view only if the screen width is less than 800px
      if (window.innerWidth < 800) {
        addRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  const scrollToPay = () => {
    if (payRef.current) {
      // Scroll into view only if the screen width is less than 800px
      if (window.innerWidth < 800) {
        payRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  };

  return (
    <div className="scroll-btn-container">
      <div className="icon-wrapper">
        <TicketPlus onClick={scrollToAdd} />
      </div>
      <div className="icon-wrapper">
        <ShoppingCart onClick={scrollToPay} />
      </div>
    </div>
  );
};

export default ScrollButtons;
