const hr = {
  ext: "ext.",
  country: "Pozivni broj države",
  phone: "Telefon",
  AB: "Abhazija",
  AC: "Otok Ascension",
  AD: "Andora",
  AE: "Ujedinjeni Arapski Emirati",
  AF: "Afganistan",
  AG: "Antigva i Barbuda",
  AI: "Angvila",
  AL: "Albanija",
  AM: "Armenija",
  AO: "Angola",
  AQ: "Antarktika",
  AR: "Argentina",
  AS: "Američka Samoa",
  AT: "Austrija",
  AU: "Australija",
  AW: "Aruba",
  AX: "Ålandski otoci",
  AZ: "Azerbajdžan",
  BA: "Bosna i Hercegovina",
  BB: "Barbados",
  BD: "Bangladeš",
  BE: "Belgija",
  BF: "Burkina Faso",
  BG: "Bugarska",
  BH: "Bahrein",
  BI: "Burundi",
  BJ: "Benin",
  BL: "Saint Barthélemy",
  BM: "Bermuda",
  BN: "Brunej",
  BO: "Bolivija",
  BQ: "Bonaire, Sint Eustatius i Saba",
  BR: "Brazil",
  BS: "Bahami",
  BT: "Butan",
  BV: "Bouvetov otok",
  BW: "Bocvana",
  BY: "Bjelorusija",
  BZ: "Belize",
  CA: "Kanada",
  CC: "Kokosovi otoci",
  CD: "Kongo, Demokratska Republika",
  CF: "Srednjoafrička Republika",
  CG: "Kongo",
  CH: "Švicarska",
  CI: "Obala Bjelokosti",
  CK: "Cookovi otoci",
  CL: "Čile",
  CM: "Kamerun",
  CN: "Kina",
  CO: "Kolumbija",
  CR: "Kostarika",
  CU: "Kuba",
  CV: "Zelenortska Republika",
  CW: "Curaçao",
  CX: "Božićni otok",
  CY: "Cipar",
  CZ: "Češka",
  DE: "Njemačka",
  DJ: "Džibuti",
  DK: "Danska",
  DM: "Dominika",
  DO: "Dominikanska Republika",
  DZ: "Alžir",
  EC: "Ekvador",
  EE: "Estonija",
  EG: "Egipat",
  EH: "Zapadna Sahara",
  ER: "Eritreja",
  ES: "Španjolska",
  ET: "Etiopija",
  FI: "Finska",
  FJ: "Fidži",
  FK: "Falklandski otoci",
  FM: "Mikronezija, Savezne Države",
  FO: "Farski otoci",
  FR: "Francuska",
  GA: "Gabon",
  GB: "Velika Britanija",
  GD: "Grenada",
  GE: "Gruzija",
  GF: "Francuska Gvajana",
  GG: "Guernsey",
  GH: "Gana",
  GI: "Gibraltar",
  GL: "Grenland",
  GM: "Gambija",
  GN: "Gvineja",
  GP: "Guadeloupe",
  GQ: "Ekvatorska Gvineja",
  GR: "Grčka",
  GS: "Južna Georgija i Južni Sandwich otoci",
  GT: "Gvatemala",
  GU: "Guam",
  GW: "Gvineja-Bissau",
  GY: "Gvajana",
  HK: "Hong Kong",
  HM: "Otok Heard i otočje McDonald",
  HN: "Honduras",
  HR: "Hrvatska",
  HT: "Haiti",
  HU: "Mađarska",
  ID: "Indonezija",
  IE: "Irska",
  IL: "Izrael",
  IM: "Otok Man",
  IN: "Indija",
  IO: "Britanski Indijskooceanski teritorij",
  IQ: "Irak",
  IR: "Iran",
  IS: "Island",
  IT: "Italija",
  JE: "Jersey",
  JM: "Jamajka",
  JO: "Jordan",
  JP: "Japan",
  KE: "Kenija",
  KG: "Kirgistan",
  KH: "Kambodža",
  KI: "Kiribati",
  KM: "Komori",
  KN: "Sveti Kristofor i Nevis",
  KP: "Sjeverna Koreja",
  KR: "Južna Koreja",
  KW: "Kuvajt",
  KY: "Kajmanski otoci",
  KZ: "Kazahstan",
  LA: "Laos",
  LB: "Libanon",
  LC: "Sveta Lucija",
  LI: "Lihtenštajn",
  LK: "Šri Lanka",
  LR: "Liberija",
  LS: "Lesoto",
  LT: "Litva",
  LU: "Luksemburg",
  LV: "Latvija",
  LY: "Libija",
  MA: "Maroko",
  MC: "Monako",
  MD: "Moldavija",
  ME: "Crna Gora",
  MF: "Saint Martin (francuski dio)",
  MG: "Madagaskar",
  MH: "Maršalovi otoci",
  MK: "Sjeverna Makedonija",
  ML: "Mali",
  MM: "Mijanmar (Burma)",
  MN: "Mongolija",
  MO: "Makao",
  MP: "Sjeverni Marijanski otoci",
  MQ: "Martinik",
  MR: "Mauritanija",
  MS: "Montserrat",
  MT: "Malta",
  MU: "Mauricijus",
  MV: "Maldivi",
  MW: "Malavi",
  MX: "Meksiko",
  MY: "Malezija",
  MZ: "Mozambik",
  NA: "Namibija",
  NC: "Nova Kaledonija",
  NE: "Niger",
  NF: "Otok Norfolk",
  NG: "Nigerija",
  NI: "Nikaragva",
  NL: "Nizozemska",
  NO: "Norveška",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  NZ: "Novi Zeland",
  OM: "Oman",
  OS: "Južna Osetija",
  PA: "Panama",
  PE: "Peru",
  PF: "Francuska Polinezija",
  PG: "Papua Nova Gvineja",
  PH: "Filipini",
  PK: "Pakistan",
  PL: "Poljska",
  PM: "Saint Pierre i Miquelon",
  PN: "Pitcairn",
  PR: "Portoriko",
  PS: "Palestina",
  PT: "Portugal",
  PW: "Palau",
  PY: "Paragvaj",
  QA: "Katar",
  RE: "Reunion",
  RO: "Rumunjska",
  RS: "Srbija",
  RU: "Rusija",
  RW: "Ruanda",
  SA: "Saudijska Arabija",
  SB: "Solomonski otoci",
  SC: "Sejšeli",
  SD: "Sudan",
  SE: "Švedska",
  SG: "Singapur",
  SH: "Sveta Helena",
  SI: "Slovenija",
  SJ: "Svalbard i Jan Mayen",
  SK: "Slovačka",
  SL: "Sijera Leone",
  SM: "San Marino",
  SN: "Senegal",
  SO: "Somalija",
  SR: "Surinam",
  SS: "Južni Sudan",
  ST: "Sveti Toma i Principe",
  SV: "Salvador",
  SX: "Sint Maarten",
  SY: "Sirija",
  SZ: "Svazi",
  TA: "Tristan da Cunha",
  TC: "Otok Turks i Caicos",
  TD: "Čad",
  TF: "Francuski južni i antarktički teritoriji",
  TG: "Togo",
  TH: "Tajland",
  TJ: "Tadžikistan",
  TK: "Tokelau",
  TL: "Istočni Timor",
  TM: "Turkmenistan",
  TN: "Tunis",
  TO: "Tonga",
  TR: "Turska",
  TT: "Trinidad i Tobago",
  TV: "Tuvalu",
  TW: "Tajvan",
  TZ: "Tanzanija",
  UA: "Ukrajina",
  UG: "Uganda",
  UM: "Mali udaljeni otoci Sjedinjenih Država",
  US: "Sjedinjene Američke Države",
  UY: "Urugvaj",
  UZ: "Uzbekistan",
  VA: "Vatikan",
  VC: "Sveti Vincent i Grenadini",
  VE: "Venezuela",
  VG: "Britanski Djevičanski otoci",
  VI: "Američki Djevičanski otoci",
  VN: "Vijetnam",
  VU: "Vanuatu",
  WF: "Wallis i Futuna",
  WS: "Samoa",
  XK: "Kosovo",
  YE: "Jemen",
  YT: "Mayotte",
  ZA: "Južna Afrika",
  ZM: "Zambija",
  ZW: "Zimbabve",
  ZZ: "Međunarodno",
};

export default hr;
