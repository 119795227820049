import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
// Images
import Logo from "../../assets/logo/logo.svg";
import LogoutIcon from "../../assets/ikonice/logout_btn.svg";
import Menu from "../../assets/ikonice/menu.svg";
// Components
import { Html5QrcodeScanner } from "html5-qrcode";
import { Link } from "react-router-dom";
import { toastSetup } from "../../functions/toastSetup";
import { toast } from "react-toastify";
// Redux
import { useDispatch } from "react-redux";
import { setUserID } from "../../store/entranceControllerSlice";
import { useNavigate } from "react-router-dom";

export const QRscanner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let dbLocalStorage = localStorage.getItem("dbId");
  let entrance = localStorage.getItem("entranceLS");
  const [ticketState, setState] = useState();
  const [loading, setLoading] = useState(false);

  let scannedTicket;
  // Scanner setup
  useEffect(() => {
    const scanner = new Html5QrcodeScanner("reader", {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 10,
      showTorchButtonIfSupported: true,
    });

    scanner.render(success, error);
  }, []);

  async function success(ticketId) {
    // console.log("NOVI", ticketId, "//////////////", scannedTicket, "STARI");

    if (scannedTicket !== ticketId) {
      setLoading(true);
      scannedTicket = ticketId;
      try {
        const response = await axios.patch(
          process.env.REACT_APP_API_URL + `/api/v1/tickets/${dbLocalStorage}`,
          {
            entrance,
            ticketId,
          }
        );
        setState(response.data);
        setLoading(false);
      } catch (error) {
        setState(error?.response?.data?.msg);
        setLoading(false);
      }
    }
  }

  let loaderClass;
  if (loading) {
    loaderClass = "scan-in-process";
  } else if (ticketState?.msg === "Neuspješno") {
    loaderClass = "failed-scan";
  } else if (ticketState?.msg === "Uspješno") {
    loaderClass = "success-scan";
  } else if (ticketState?.msg === "Greška") {
    loaderClass = "error-scan";
  } else {
    loaderClass = "scan-in-process";
  }
  // console.log("ticketState", ticketState);
  function error(err) {
    // console.warn(err);
  }

  // Logout from QR scanner
  function logout() {
    dispatch(setUserID(""));
    localStorage.setItem("entranceControllerId", "");
    localStorage.setItem("dbId", "");
    navigate("/controller_login");
    toast.success("Uspješna odjava", toastSetup("top-right", 2000));
  }

  return (
    <div className="qr-scanner-container">
      <nav className="navbar navbar-expand-lg custom-navbar">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="Logo" />
            <p>event.ba</p>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src={Menu} className="navbar-toggler-icon" alt="Menu" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav qr-sacnner-nav">
              <li className="nav-item">
                <button className="controller-logout-btn" onClick={logout}>
                  Odjavi se
                  <img src={LogoutIcon} alt="Logout" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div id="reader"></div>

      <div className={`qr-message ${loaderClass}`}>
        {!loading ? (
          <>
            <p>{ticketState?.owner || ticketState?.msgInfo || "Skeniraj!"}</p>

            <span>Status: {ticketState?.msgInfo || ""}</span>
            <span>Zona: {ticketState?.category || ""}</span>
            <span>
              Sken ({ticketState?.times_scanned || 0}) : {ticketState?.scannedAt}
            </span>
          </>
        ) : (
          <span className="loader"></span>
        )}
      </div>

      <h3>
        {/* '   {ticketState &&
          (String(entrance).trim(" ") === String(ticketState?.category).trim(" ") ? (
            <div className="success-scan entrance">DOBAR ULAZ</div>
          ) : (
            <div className="failed-scan entrance">POGREŠAN ULAZ</div>
          ))}{" "} */}
        ULAZ: {entrance}
      </h3>
    </div>
  );
};
