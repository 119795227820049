// ErrorScan component
import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import TicketModal from "./TicketModal";
import { RefreshCw } from "lucide-react";

const ErrorScan = ({ concertId }) => {
  const token = useSelector((state) => state.userState.token);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTicketId, setSelectedTicketId] = useState(null);

  const getScanErrors = async () => {
    setLoading(true);
    setError(null); // Reset error state before the fetch
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/helper/get_scan_errors/tickets_for_${concertId}`,
        {
          token,
        }
      );
      setData(response.data.scanLogs);
    } catch (error) {
      setError("Error fetching concert data");
      console.error("Error fetching concert data:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  return (
    <>
      <div className="wrapper-header">
        <h6>Greške pri skeniranju</h6>
        <button style={{ marginRight: "10px" }} disabled={loading} onClick={getScanErrors}>
          <RefreshCw style={{ marginRight: "10px" }} />
          Osvježi
        </button>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {data && (
        <table>
          <thead>
            <tr>
              <th>Ulaznica</th>
              <th>Ulaz</th>
              <th>Poruka</th>
              <th>Vrijeme</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td onClick={() => setSelectedTicketId(item.ticketId)}>
                  {item?.ticketId ? (
                    <div style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>
                      Info
                    </div>
                  ) : (
                    "Nema ID"
                  )}
                </td>
                <td>{item.entrance}</td>
                <td>{item.msg}</td>
                <td>
                  {new Intl.DateTimeFormat("hr-HR", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(new Date(item.createdAt))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {selectedTicketId && concertId && (
        <TicketModal
          ticketId={selectedTicketId}
          concertId={concertId}
          onClose={() => setSelectedTicketId(null)}
        />
      )}
    </>
  );
};

export default ErrorScan;
